@import url(https://fonts.googleapis.com/css?family=VT323);

p:first-of-type {
  margin-top: 0;
}

p {
  margin: 1rem 0;
}

.base {
  border-radius: 50%;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.frame {
  background: linear-gradient(rgba(0, 0, 0, 0.5), 45%, transparent), #333;
  border-radius: 50%;
  border: 0.5rem #222 solid;
  box-shadow: 0 2rem 2rem rgba(0, 0, 0, 0.3), 0 0.25rem 0 rgba(0, 0, 0, 0.15);
  flex: 1;
  height: auto;
  margin: 2rem;
  max-width: calc(100vh - 4rem);
  position: relative;
  width: 100%;
}

.frame::before {
  content: '';
  float: left;
  padding-top: 100%;
}

.screen {
  composes: base;
  box-sizing: border-box;
  animation: text 10ms infinite;
  text-shadow: 0rem 0.2rem 1rem #ffcd0080;
  color: #ffcd00;
  font-family: 'VT323';
  font-size: 1.2rem;
  overflow: auto;
  padding: 15%;
  position: absolute;
}

.screen::-webkit-scrollbar {
  display: none;
}

.scanlines {
  composes: base;
  background: linear-gradient(
    to bottom,
    transparent,
    transparent 50%,
    rgba(0, 0, 0, 0.25) 70%,
    rgba(0, 0, 0, 0.75)
  );
  background-size: 100% 3px;
  pointer-events: none;
  position: absolute;
}

.shadow {
  composes: base;
  border-radius: 50%;
  box-shadow: inset 0 0 18rem #00000066, inset 0 2rem 10rem #00000066;
  pointer-events: none;
  position: absolute;
}

.glow {
  composes: base;
  background: radial-gradient(
    circle at center,
    #ffcd00 0%,
    rgba(255, 80, 0, 0.3) 100%
  );
  box-shadow: 0 3rem 5rem 3rem #ffcd0033;
  opacity: 0.12;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
}

.glow::after {
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
  content: '';
  height: 50%;
  position: absolute;
  transform: rotate(-5deg) skew(10deg) translateY(60%) translateX(250%);
  width: 30%;
}

::selection {
  background-color: #ffcd00;
  color: #333;
}

@keyframes text {
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 576px) {
  .screen {
    font-size: 1.5rem;
  }
}

@media (min-width: 1366px) {
  .screen {
    font-size: 2rem;
  }
}
